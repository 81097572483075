// extracted by mini-css-extract-plugin
export var animatedBtn = "sectionTestimonial-module--animated-btn--jZbpT";
export var bannerHeader = "sectionTestimonial-module--banner-header--JlP2w";
export var btn = "sectionTestimonial-module--btn--4z27w";
export var clientsDetails = "sectionTestimonial-module--clientsDetails--QGT+k";
export var container = "sectionTestimonial-module--container--o8mpi";
export var dark = "sectionTestimonial-module--dark--MwX2d";
export var detailsTextPortable = "sectionTestimonial-module--detailsTextPortable--afH+c";
export var deviceMockupsWrapper = "sectionTestimonial-module--device-mockups-wrapper--exXaA";
export var headerFeatures = "sectionTestimonial-module--header-features--mfe+9";
export var intro = "sectionTestimonial-module--intro--TwtnX";
export var lineIntro = "sectionTestimonial-module--line-intro--pww0X";
export var link = "sectionTestimonial-module--link--2d5Xg";
export var marqueText = "sectionTestimonial-module--marque-text--oH51A";
export var menuBackground = "sectionTestimonial-module--menu-background--OPXY1";
export var menuLink = "sectionTestimonial-module--menu-link--Au8FK";
export var overlay = "sectionTestimonial-module--overlay--UW33G";
export var pageBtn = "sectionTestimonial-module--page-btn--RTYn8";
export var question = "sectionTestimonial-module--question--K1Oej";
export var quoteIcon = "sectionTestimonial-module--quoteIcon--cLx5a";
export var quoteIconLeft = "sectionTestimonial-module--quoteIconLeft--x+jF2";
export var quoteIconProject = "sectionTestimonial-module--quoteIconProject--EGy66";
export var quoteIconRight = "sectionTestimonial-module--quoteIconRight--UhZda";
export var sectionTestimonial = "sectionTestimonial-module--sectionTestimonial--EKmXP";
export var sectionTestimonialProject = "sectionTestimonial-module--sectionTestimonialProject--en1gU";
export var serviceSingle = "sectionTestimonial-module--serviceSingle--6DoxD";
export var testimonial = "sectionTestimonial-module--testimonial--hu0v7";
export var testimonialImage = "sectionTestimonial-module--testimonialImage--1k4HY";
export var testimonialServicesList = "sectionTestimonial-module--testimonialServicesList--ZSwFB";
export var testimonialText = "sectionTestimonial-module--testimonialText--QdIYg";
export var testimonialTextHome = "sectionTestimonial-module--testimonialTextHome--deyJI";
export var testimonialTextPortable = "sectionTestimonial-module--testimonialTextPortable--AE2t2";
export var testimonialTextProject = "sectionTestimonial-module--testimonialTextProject--DRqmM";
export var testimonialWrapper = "sectionTestimonial-module--testimonialWrapper--jQmzU";
export var textColor = "sectionTestimonial-module--text-color--xMv9n";
export var textUppercase = "sectionTestimonial-module--text-uppercase--tYHSN";