// extracted by mini-css-extract-plugin
export var animatedBtn = "projectSingleGallery-module--animated-btn--ImJgh";
export var bannerHeader = "projectSingleGallery-module--banner-header--ejQhB";
export var btn = "projectSingleGallery-module--btn--0tA8k";
export var container = "projectSingleGallery-module--container--XqjhB";
export var dark = "projectSingleGallery-module--dark--YH0oj";
export var detailsTextPortable = "projectSingleGallery-module--detailsTextPortable--xVADG";
export var deviceMockupsWrapper = "projectSingleGallery-module--device-mockups-wrapper--+JPNo";
export var galleryImages = "projectSingleGallery-module--galleryImages--dnNgh";
export var headerFeatures = "projectSingleGallery-module--header-features--fc5cT";
export var intro = "projectSingleGallery-module--intro--eoaGq";
export var lineIntro = "projectSingleGallery-module--line-intro--CBxHL";
export var link = "projectSingleGallery-module--link--vBc1E";
export var marqueText = "projectSingleGallery-module--marque-text--fqdNt";
export var menuBackground = "projectSingleGallery-module--menu-background--RblHZ";
export var menuLink = "projectSingleGallery-module--menu-link--Vke7O";
export var otherGallery = "projectSingleGallery-module--otherGallery--1CMtx";
export var overlay = "projectSingleGallery-module--overlay--PpYAD";
export var pageBtn = "projectSingleGallery-module--page-btn--YO2a3";
export var projectGallery = "projectSingleGallery-module--projectGallery--Hy4oF";
export var question = "projectSingleGallery-module--question--BYsKV";
export var testimonial = "projectSingleGallery-module--testimonial--hjTOj";
export var textColor = "projectSingleGallery-module--text-color--b8cm0";
export var textUppercase = "projectSingleGallery-module--text-uppercase--cpWXm";