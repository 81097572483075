// extracted by mini-css-extract-plugin
export var animatedBtn = "projectSingleDetails-module--animated-btn--4cjB+";
export var bannerHeader = "projectSingleDetails-module--banner-header--A-omp";
export var btn = "projectSingleDetails-module--btn--K-kw8";
export var container = "projectSingleDetails-module--container--5wAUL";
export var dark = "projectSingleDetails-module--dark--YK1at";
export var detailsTextPortable = "projectSingleDetails-module--detailsTextPortable--kiSw-";
export var deviceMockupsWrapper = "projectSingleDetails-module--device-mockups-wrapper--GIPDU";
export var headerFeatures = "projectSingleDetails-module--header-features--dSY93";
export var intro = "projectSingleDetails-module--intro--dHkCX";
export var lineIntro = "projectSingleDetails-module--line-intro--bWRUP";
export var link = "projectSingleDetails-module--link--m3FRP";
export var marqueText = "projectSingleDetails-module--marque-text--U8MgG";
export var menuBackground = "projectSingleDetails-module--menu-background--tJiqO";
export var menuLink = "projectSingleDetails-module--menu-link--ob+rT";
export var overlay = "projectSingleDetails-module--overlay--mvqhb";
export var overviewName = "projectSingleDetails-module--overviewName--xz-tk";
export var pageBtn = "projectSingleDetails-module--page-btn--CKf5M";
export var projectDetails = "projectSingleDetails-module--projectDetails--v0T5a";
export var projectDetailsHeader = "projectSingleDetails-module--projectDetailsHeader--tFjE9";
export var projectDetailsLeft = "projectSingleDetails-module--projectDetailsLeft--7UxM3";
export var projectDetailsOverview = "projectSingleDetails-module--projectDetailsOverview--NDmYL";
export var projectDetailsRight = "projectSingleDetails-module--projectDetailsRight--EsaWf";
export var projectOverviewCard = "projectSingleDetails-module--projectOverviewCard--fxB++";
export var question = "projectSingleDetails-module--question--MsPCn";
export var testimonial = "projectSingleDetails-module--testimonial--yMnfT";
export var textColor = "projectSingleDetails-module--text-color--ePf5X";
export var textUppercase = "projectSingleDetails-module--text-uppercase--EMNpl";
export var toolSingle = "projectSingleDetails-module--toolSingle--yJsms";
export var toolsList = "projectSingleDetails-module--toolsList--mgZUB";