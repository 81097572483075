// extracted by mini-css-extract-plugin
export var aboutDescription = "description-module--aboutDescription--L8rH+";
export var animatedBtn = "description-module--animated-btn--ZHzBY";
export var bannerHeader = "description-module--banner-header--j1-aT";
export var btn = "description-module--btn--SPbTn";
export var container = "description-module--container--+6+75";
export var dark = "description-module--dark--v954O";
export var description = "description-module--description--zvIyi";
export var detailsTextPortable = "description-module--detailsTextPortable--0AYGT";
export var deviceMockupsWrapper = "description-module--device-mockups-wrapper--np8yi";
export var headerFeatures = "description-module--header-features--ZtvF+";
export var intro = "description-module--intro--t1WFA";
export var lineIntro = "description-module--line-intro--aTcCy";
export var link = "description-module--link--+vd99";
export var marqueText = "description-module--marque-text--61ypE";
export var menuBackground = "description-module--menu-background---IaXF";
export var menuLink = "description-module--menu-link--z4l87";
export var overlay = "description-module--overlay--h-xxC";
export var pageBtn = "description-module--page-btn--z1GjN";
export var projectDescription = "description-module--projectDescription--iehIf";
export var question = "description-module--question--Xojn+";
export var testimonial = "description-module--testimonial--TlEb7";
export var textColor = "description-module--text-color--yYBVz";
export var textUppercase = "description-module--text-uppercase--qpyZH";