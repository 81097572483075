// extracted by mini-css-extract-plugin
export var animatedBtn = "banner-module--animated-btn--fBNsp";
export var bannerHeader = "banner-module--banner-header--Pd1O1";
export var btn = "banner-module--btn--hJ0Mb";
export var container = "banner-module--container--eA1PM";
export var dark = "banner-module--dark--CXj+b";
export var detailsTextPortable = "banner-module--detailsTextPortable--euBxy";
export var deviceMockupsWrapper = "banner-module--device-mockups-wrapper--obYOg";
export var headerFeatures = "banner-module--header-features--UAYay";
export var intro = "banner-module--intro--EmkRx";
export var lineIntro = "banner-module--line-intro--VH2Ab";
export var link = "banner-module--link--8Ccqe";
export var marqueText = "banner-module--marque-text--R7gg9";
export var menuBackground = "banner-module--menu-background--WfiP4";
export var menuLink = "banner-module--menu-link--Dy+jl";
export var overlay = "banner-module--overlay--sHfoA";
export var pageBtn = "banner-module--page-btn--YqeQl";
export var projectBanner = "banner-module--projectBanner--ysapt";
export var projectBannerDetails = "banner-module--projectBannerDetails--2I63U";
export var projectBannerHeader = "banner-module--projectBannerHeader--rEi1m";
export var projectImageContainer = "banner-module--projectImageContainer--EPsne";
export var question = "banner-module--question--JxtrP";
export var serviceSingle = "banner-module--serviceSingle--4eJE0";
export var servicesList = "banner-module--servicesList--qXdO0";
export var testimonial = "banner-module--testimonial--1UfHE";
export var textColor = "banner-module--text-color--RCg9o";
export var textUppercase = "banner-module--text-uppercase--to41A";