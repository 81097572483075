// extracted by mini-css-extract-plugin
export var animatedBtn = "sectionFeaturedVideo-module--animated-btn--QenyS";
export var bannerHeader = "sectionFeaturedVideo-module--banner-header--w-+K+";
export var btn = "sectionFeaturedVideo-module--btn--y+i9u";
export var container = "sectionFeaturedVideo-module--container--rz9l7";
export var dark = "sectionFeaturedVideo-module--dark--aeUwq";
export var detailsTextPortable = "sectionFeaturedVideo-module--detailsTextPortable--1tS5j";
export var deviceMockupsWrapper = "sectionFeaturedVideo-module--device-mockups-wrapper--rWJER";
export var featuredVideo = "sectionFeaturedVideo-module--featuredVideo--Urvq5";
export var featuredVideoSection = "sectionFeaturedVideo-module--featuredVideoSection--iWzEP";
export var headerFeatures = "sectionFeaturedVideo-module--header-features--6MlwD";
export var intro = "sectionFeaturedVideo-module--intro--ltQBV";
export var lineIntro = "sectionFeaturedVideo-module--line-intro--dixao";
export var link = "sectionFeaturedVideo-module--link--gjPZr";
export var marqueText = "sectionFeaturedVideo-module--marque-text--p0gkP";
export var menuBackground = "sectionFeaturedVideo-module--menu-background--Ff86o";
export var menuLink = "sectionFeaturedVideo-module--menu-link--UYqHd";
export var overlay = "sectionFeaturedVideo-module--overlay--MKAhc";
export var pageBtn = "sectionFeaturedVideo-module--page-btn--b1hK8";
export var question = "sectionFeaturedVideo-module--question--XVhhd";
export var testimonial = "sectionFeaturedVideo-module--testimonial--iog8q";
export var textColor = "sectionFeaturedVideo-module--text-color--SUYwj";
export var textUppercase = "sectionFeaturedVideo-module--text-uppercase--DVuRi";