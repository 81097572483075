// extracted by mini-css-extract-plugin
export var animatedBtn = "projectSingleNext-module--animated-btn--Fm0a3";
export var bannerHeader = "projectSingleNext-module--banner-header--S15pi";
export var btn = "projectSingleNext-module--btn--g54tQ";
export var container = "projectSingleNext-module--container--SNLw9";
export var dark = "projectSingleNext-module--dark--ZwnbG";
export var detailsTextPortable = "projectSingleNext-module--detailsTextPortable--ELdP7";
export var deviceMockupsWrapper = "projectSingleNext-module--device-mockups-wrapper--VaBKU";
export var headerFeatures = "projectSingleNext-module--header-features--LHXwG";
export var intro = "projectSingleNext-module--intro--Oi-BB";
export var lineIntro = "projectSingleNext-module--line-intro--0nIVp";
export var link = "projectSingleNext-module--link--xbozI";
export var marqueText = "projectSingleNext-module--marque-text--WU+NW";
export var menuBackground = "projectSingleNext-module--menu-background--WWFPm";
export var menuLink = "projectSingleNext-module--menu-link--QnB1+";
export var nextProjectHeader = "projectSingleNext-module--nextProjectHeader--cN4bW";
export var nextProjectHeaderWrapper = "projectSingleNext-module--nextProjectHeaderWrapper--vV2oI";
export var nextProjectImage = "projectSingleNext-module--nextProjectImage--bwJ+s";
export var nextProjectSection = "projectSingleNext-module--nextProjectSection--9iFGH";
export var overlay = "projectSingleNext-module--overlay--LugBv";
export var pageBtn = "projectSingleNext-module--page-btn--DNHLQ";
export var question = "projectSingleNext-module--question--ak7jY";
export var testimonial = "projectSingleNext-module--testimonial--qipCl";
export var textColor = "projectSingleNext-module--text-color--TYdwk";
export var textUppercase = "projectSingleNext-module--text-uppercase--ycooH";